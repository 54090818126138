.WelcomeBox {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: calc(100 * var(--vh) - 64px);
  overflow-y: hidden;
  padding-top: calc(15 * var(--vh));
}

.MCastLogo img {
  width: 10em;
}

.AppLogo {
  margin: calc(10 * var(--vh)) 0;
}

.AppLogo svg {
  font-size: 16em;
}
