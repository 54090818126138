.LoadingScreen {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 24px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingWrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: calc(80 * var(--vh));
  justify-content: space-evenly;
}

.CommAppLogo {
  margin-top: 0.2em;
  height: 0.5em;
  font-size: 8em;
}

.LoadingSvg {
  height: 0.5em;
  font-size: 10em;
}
